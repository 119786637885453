import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Button, Toolbar, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TopBar from '../components/TopBar';
import { getVideos, validateVideo } from '../services/api'; // Importar las funciones desde api.js

const useStyles = makeStyles(() => ({
    video: {
        maxHeight: '650px',
        width: '100%',
        objectFit: 'contain',
    },
    dateText: {
        marginTop: '10px',
        color: '#555',
    },
    validationStatus: {
        marginTop: '10px',
        fontWeight: 'bold',
    },
    validated: {
        color: 'green',
    },
    notValidated: {
        color: 'red',
    },
    unreviewed: {
        color: 'black',
    },
    paginationButtons: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
}));

function Videos() {
    const classes = useStyles();
    const [videos, setVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await getVideos(currentPage); // Usar la función getVideos desde api.js
                console.log('Videos fetched:', response.data.data);
                setVideos(response.data.data);
                setTotalPages(response.data.last_page); // Ajustar para el número total de páginas
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        };
        fetchVideos();
    }, [currentPage]);

    const handleValidation = async (id, isValidated) => {
        console.log(`Validating video ID: ${id} with status: ${isValidated}`);
        try {
            const response = await validateVideo(id, isValidated); // Usar la función validateVideo desde api.js
            console.log(`Validation response for video ID: ${id}`, response);
            alert(`Video ID ${id} fue ${isValidated ? 'aprobado' : 'no aprobado'}.`);
        } catch (error) {
            console.error(`Error validating video ID: ${id}`, error);
            alert(`Hubo un error al validar el video ID ${id}.`);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const getValidationStatus = (isValidated) => {
        if (isValidated === 1) {
            return <Typography className={`${classes.validationStatus} ${classes.validated}`}>VIDEO VALIDADO</Typography>;
        } else if (isValidated === 0) {
            return <Typography className={`${classes.validationStatus} ${classes.notValidated}`}>VIDEO NO VALIDADO</Typography>;
        } else {
            return <Typography className={`${classes.validationStatus} ${classes.unreviewed}`}>VIDEO SIN VALIDAR</Typography>;
        }
    };

    return (
        <div>
            <TopBar />
            <Toolbar />  {/* Espaciado para que el contenido no quede debajo del TopBar */}
            <Container>
                <Grid container spacing={3}>
                    {videos.map(video => (
                        <Grid item xs={12} sm={6} md={4} key={video.id}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5">{video.title}</Typography>
                                    <Typography>{video.description}</Typography>
                                    <video className={classes.video} controls>
                                        <source src={video.link} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <Typography className={classes.dateText}>
                                        Video creado el: {new Date(video.created_at).toLocaleString()}
                                    </Typography>
                                    <Typography className={classes.dateText}>
                                        Video actualizado el: {new Date(video.updated_at).toLocaleString()}
                                    </Typography>
                                    {getValidationStatus(video.is_validated)}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleValidation(video.id, true)}
                                    >
                                        Aprobar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleValidation(video.id, false)}
                                    >
                                        No Aprobar
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* Controles de paginación */}
                <div className={classes.paginationButtons}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </Button>
                    <Typography variant="body1" style={{ margin: '0 15px' }}>
                        Página {currentPage} de {totalPages}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Siguiente
                    </Button>
                </div>
            </Container>
        </div>
    );
}

export default Videos;
