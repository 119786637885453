import axios from 'axios';

const API_URL = 'https://api.kon-app.com/api';


export function login(credentials) {
    return axios.post(`${API_URL}/login`, credentials)
        .then(response => response.data)
        .catch(error => {
            if (error.response && error.response.status === 401) {
                throw new Error('Unauthorized');
            } else {
                throw error;
            }
        });
}

export function getAnalytics(token) {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return axios.get(`${API_URL}/analytics`, { headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export function validateToken(token) {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return axios.get(`${API_URL}/validate-token`, { headers })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export function getVideos(page = 1) {
    const token = localStorage.getItem('access_token');
    return axios.get(`${API_URL}/video?page=${page}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

export function validateVideo(id, isValidated) {
    const token = localStorage.getItem('access_token');
    return axios.put(`${API_URL}/videos/${id}/validate`,
        { is_validated: isValidated },
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
}
