import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import { useNotify, useRedirect } from 'react-admin';
import {login as apiLogin, validateToken} from '../services/api';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    minWidth: '100vw',
    backgroundColor: theme.palette.background.default,
  },
  card: {
    minWidth: 300,
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}));

const Login = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      validateToken(access_token)
          .then((response) => {
            setAuthenticated(true);
            redirectTo('/dashboard');
          })
          .catch((error) => {
            localStorage.removeItem('access_token');
            setAuthenticated(false);
          });
    }
  }, [redirectTo]);


  const handleSubmit = (event) => {
    event.preventDefault();

    const token = window.grecaptcha.getResponse();
    if (!token) {
      setError('Por favor, completa el captcha');
      return;
    }

    if (authenticated) {
      redirectTo('/dashboard');
      //return;
      return null;
    }

    apiLogin({ username, password })
        .then((response) => {
          localStorage.setItem('access_token', response.access_token);
          notify('Logged in');
          redirectTo('/dashboard');
        })
        .catch((error) => {
          if (error.message === 'Unauthorized') {
            setError('Valida por favor tu usuario o contraseña');
          } else {
            notify('Invalid username or password');
          }
        });
  };

  /*
  const handleSubmit = (event) => {
    event.preventDefault();

    const token = window.grecaptcha.getResponse();
    if (!token) {
      setError('Por favor, completa el captcha');
      return;
    }

    apiLogin({ username, password })
        .then((response) => {
          localStorage.setItem('access_token', response.access_token);
          notify('Logged in');
          redirectTo('/dashboard');
        })
        .catch((error) => {
          if (error.message === 'Unauthorized') {
            setError('Valida por favor tu usuario o contraseña');
          } else {
            notify('Invalid username or password');
          }
        });
  };
  */

  /*
  const validateAccessToken = () => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      return validateToken(access_token)
          .then(data => {
            if (data.message === 'Valid token') {
              return true;
            } else {
              localStorage.removeItem('access_token');
              return false;
            }
          })
          .catch(error => {
            console.error(error);
            return false;
          });
    } else {
      return Promise.resolve(false);
    }
  };
  */


  return (
      <Container className={classes.container} maxWidth="xs">
        <Card className={classes.card} elevation={3}>
          <CardContent>
            <Typography component="h1" variant="h5">
              Inicio de sesión
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Usuario"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
              />
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
              />
              {error && (
                  <FormHelperText className={classes.errorText}>
                    {error}
                  </FormHelperText>
              )}
              <div className="g-recaptcha" data-sitekey="6LdpfYElAAAAAHa-0SEzOVDBKSKAKrLpehLEgLQc"></div>
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
              >
                Iniciar sesión
              </Button>
            </form>
          </CardContent>
        </Card>
      </Container>
  );
};

export default Login;

