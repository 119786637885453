import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TopBar from '../components/TopBar';
import { getAnalytics } from '../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    card: {
        height: '100%',
    },
    cardContent: {
        padding: '16px 24px',
    },
    cardTitle: {
        fontSize: '1.25rem',
    },
}));

function Dashboard() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [analyticsData, setAnalyticsData] = useState({});

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');
        if (!access_token) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            getAnalytics(access_token)
                .then(data => setAnalyticsData(data))
                .catch(error => console.error(error));
        }
    }, []);

    function handleLogout() {
        localStorage.removeItem('access_token');
        navigate('/');
    }

    return (
        <div className={classes.root}>
            <TopBar handleLogout={handleLogout}/>
            <main className={classes.content}>
                <Toolbar/>
                <Typography variant="h4" gutterBottom>
                    Dashboard
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardHeader title="Total de videos"/>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h5" color="textPrimary" component="p" className={classes.cardTitle}>
                                    {analyticsData.total_videos}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardHeader title="Videos subidos hoy"/>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h5" color="textPrimary" component="p" className={classes.cardTitle}>
                                    {analyticsData.videos_today}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardHeader title="Total de usuarios"/>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h5" color="textPrimary" component="p" className={classes.cardTitle}>
                                    {analyticsData.total_users}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Card className={classes.card}>
                            <CardHeader title="Usuarios registrados hoy"/>
                            <CardContent className={classes.cardContent}>
                                <Typography variant="h5" color="textPrimary" component="p" className={classes.cardTitle}>
                                    {analyticsData.users_today}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}

export default Dashboard;
